import { useTranslations } from "next-intl";
import { forwardRef } from "react";

import { Text } from "@/common/components/text";
import { TrustPilotProps } from "./trustpilot";

export const TrustpilotCarousel = forwardRef<HTMLDivElement, TrustPilotProps>(
  ({ businessId }, ref) => {
    const t = useTranslations("common");
    return (
      <div className="mx-auto mb-12 flex flex-col px-4 md:mb-14 md:px-16 xl:mb-16 2xl:px-16">
        <Text variant="mediumHeading" className="mb-6 text-center md:mb-8">
          {t("text-see-what-customers-say")}
        </Text>
        <div
          data-businessunit-id={businessId}
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-style-width="100%"
          data-style-max-width="700px"
          data-theme="light"
          data-stars="5"
        >
          <a
            href="https://uk.trustpilot.com/review/wherethetradebuys.co.uk"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </div>
    );
  },
);

TrustpilotCarousel.displayName = "TrustpilotCarousel";
