"use client";

import { Ref, RefObject, useEffect, useRef, useState } from "react";

import { TrustPilotProps } from "./trustpilot";
import { TrustpilotCarousel } from "./trustpilot-carousel";
import { TrustPilotProductMini } from "./trustpilot-product-mini";
import { TrustPilotProductReview } from "./trustpilot-product-review";
import { TrustPilotProductReviews } from "./trustpilot-product-reviews";
import { TrustPilotServiceMini } from "./trustpilot-service-mini";

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (ref: Ref<any>, flag: boolean) => void;
    };
  }
}

function loadWidget(ref: RefObject<HTMLDivElement>) {
  if (window?.Trustpilot && ref.current) {
    window.Trustpilot.loadFromElement(ref.current as unknown as Ref<HTMLDivElement>, true);
  }
}

export function TrustpilotRenderer({ templateType = "carousel", ...props }: TrustPilotProps) {
  const ref = useRef<HTMLDivElement>(null);
  const defaultRef = useRef<HTMLDivElement>(null);
  const [isWidgetCollapsed, setIsWidgetCollapsed] = useState(false);

  /**
   * On first page load we will load standard ref
   * After some iframe loading if the widget is collapsed we will load
   * default widget if it exist
   */
  useEffect(() => {
    if (!isWidgetCollapsed) loadWidget(ref);
    if (isWidgetCollapsed) loadWidget(defaultRef);
  }, [isWidgetCollapsed]);

  /**
   * This effect will wrap first loaded trustpilot container into the
   * ResizedObserver this needed to track iframe loading and the reason for that is
   * understanding if Widget related to product has reviews or not
   */
  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(() => {
        const child = ref.current?.children[0];
        /**
         * If Trustpilot container has data-no-reviews set to collapse
         * and first child is iframe and style.diplay is set to none that mean that content
         * of the iframe is collapsed
         */
        if (
          ref.current?.getAttribute("data-no-reviews") === "collapse" &&
          child &&
          child.tagName === "IFRAME" &&
          getComputedStyle(child).getPropertyValue("display") === "none" &&
          !isWidgetCollapsed
        ) {
          setIsWidgetCollapsed(true);
        }
      });
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref.current]);

  switch (templateType) {
    case "product-reviews":
      return <TrustPilotProductReviews {...props} ref={ref} />;
    case "product-review":
      return <TrustPilotProductReview {...props} ref={ref} isWidgetCollapsed={isWidgetCollapsed} />;
    case "product-mini":
      return (
        <>
          <TrustPilotProductMini {...props} ref={ref} />
          {isWidgetCollapsed && <TrustPilotServiceMini {...props} ref={defaultRef} />}
        </>
      );
    case "carousel":
      return <TrustpilotCarousel {...props} ref={ref} />;
  }
}
