import { forwardRef } from "react";

import { TrustPilotProps } from "./trustpilot";

export const TrustPilotProductReviews = forwardRef<HTMLDivElement, TrustPilotProps>(
  ({ businessId }, ref) => {
    return (
      <div className="mx-auto mb-12 px-4 md:mb-14 md:px-16 xl:mb-16 2xl:px-16">
        <div
          data-businessunit-id={businessId}
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="544a426205dc0a09088833c6"
          data-style-height="300px"
          data-style-width="100%"
          data-theme="light"
          data-review-languages="en"
          data-sku="BID-93"
          data-no-reviews="hide"
          data-fullwidth="true"
        >
          <a
            href="https://uk.trustpilot.com/review/wherethetradebuys.co.uk"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </div>
    );
  },
);

TrustPilotProductReviews.displayName = "TrustPilotProductReviews";
