import { useTranslations } from "next-intl";
import { forwardRef } from "react";

import { TrustPilotProps } from "./trustpilot";

export const TrustPilotProductReview = forwardRef<HTMLDivElement, TrustPilotProps>(
  ({ isWidgetCollapsed, businessId, product: { sku, title } = {} }, ref) => {
    const t = useTranslations("common");

    return (
      <div>
        { !isWidgetCollapsed && (
          <p className="!mb-8 mt-10 text-center text-2xl font-semibold">
            {t("text-see-what-customers-say-about", { productName: title })}
          </p>
        )}
        <div
          ref={ref}
          data-businessunit-id={businessId}
          data-sku={sku}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5717796816f630043868e2e8"
          data-style-height="300px"
          data-style-width="100%"
          data-theme="light"
          data-name="Recycled Saddle Stitch Booklet Printing"
          data-review-languages="en"
          data-no-reviews="collapse"
          data-fullwidth="true"
        >
          <a href="https://uk.trustpilot.com/review/wttb.co.uk" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div>
    );
  },
);

TrustPilotProductReview.displayName = "TrustPilotProductReview";
