import { forwardRef } from "react";

import { TrustPilotProps } from "./trustpilot";

export const TrustPilotProductMini = forwardRef<HTMLDivElement, TrustPilotProps>(
  ({ businessId, product: { sku } = {} }, ref) => {
    return (
      <div
        data-businessunit-id={businessId}
        data-sku={sku}
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="54d39695764ea907c0f34825"
        data-style-height="24px"
        data-theme="light"
        data-review-languages="en"
        data-no-reviews="collapse"
        data-style-alignment="left"
      >
        <a
          href="https://uk.trustpilot.com/review/wherethetradebuys.co.uk"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    );
  },
);

TrustPilotProductMini.displayName = "TrustPilotProductMini";
