import { forwardRef } from "react";

import { TrustPilotProps } from "./trustpilot";

export const TrustPilotServiceMini = forwardRef<HTMLDivElement, TrustPilotProps>(
  ({ businessId }, ref) => {
    return (
      <div
        data-businessunit-id={businessId}
        ref={ref}
        className="trustpilot-widget -ml-2"
        data-locale="en-GB"
        data-template-id="5419b732fbfb950b10de65e5"
        data-style-height="24px"
        data-style-width="200px"
        data-theme="light"
        data-review-languages="en"
        data-style-alignment="left"
      >
        <a
          href="https://uk.trustpilot.com/review/wherethetradebuys.co.uk"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    );
  },
);

TrustPilotServiceMini.displayName = "TrustPilotServiceMini";
